import cc from "classnames"

import { $auth } from "@stores/auth"
import { getPaletteColors } from "@utils/getPaletteColors"

import Container from "@components/Container"
import Image from "@components/Image"
import SpacingContainer from "@components/SpacingContainer"
import CmsLink from "@components/CmsLink"
import CmsButton from "@components/CmsButton"
import { useStore } from "@nanostores/react"

interface ImageTextTwoColumn extends Domain.Shared.DataTransferObjects.BlockBaseConfigData {
  mirrored?: boolean
  full_screen?: boolean
  cta: Domain.Shared.ValueObjects.Link[]
  headline: string
  media: Domain.Shared.DataTransferObjects.MediaResourceData
  background_color: Domain.Shared.Enums.ColorPaletteEnum
  color_palette: Domain.Shared.Enums.ColorPaletteEnum
}

const ImageTextTwoColumn = (props: ImageTextTwoColumn): JSX.Element => {
  const { mirrored, full_screen } = props
  const { margin_top, margin_bottom, padding_top, padding_bottom, background_color } = props
  const spacingMarginTop = margin_top?.value ?? "lg"
  const spacingMarginBottom = margin_bottom?.value ?? "none"
  const spacingPaddingTop = padding_top?.value ?? "none"
  const spacingPaddingBottom = padding_bottom?.value ?? "none"

  const palette = getPaletteColors(props.color_palette)

  const { user } = useStore($auth)

  const isHidden = (props.hide_for_logged_in_users && user) || (props.hide_for_logged_out_users && !user)

  if (isHidden) return <></>

  return (
    <SpacingContainer
      margin_top={spacingMarginTop}
      margin_bottom={spacingMarginBottom}
      padding_top={spacingPaddingTop}
      padding_bottom={spacingPaddingBottom}
      background_color={background_color}
    >
      <Container almost_full_width={full_screen}>
        <div className={cc("grid grid-cols-24 md:grid-flow-col-dense", { "overflow-hidden rounded-8": !full_screen })}>
          <div className={cc("col-span-24 md:col-span-12", { "md:col-start-13": !mirrored })}>
            <div className="aspect-h-16 aspect-w-17 md:aspect-h-9 md:aspect-w-8">
              <Image
                src={props.media.permalink}
                alt={props.media.alt}
                focal_points={props.media.focus}
                span={12}
                span_lg={6}
                aspect_ratio="8:9"
                loading={props.is_above_fold ? "eager" : undefined}
                className="object-cover"
                fill
              />
            </div>
          </div>
          <div
            className={`col-span-24 text-center md:col-span-12 ${palette ? `bg-${palette.mainColor}` : "bg-spinach"} ${
              palette ? `text-${palette.mainTextColor}` : "text-white"
            } flex flex-col items-center justify-center px-24 py-56 md:px-56`}
          >
            <div
              className={cc("mb-32 font-medium leading-[120%] md:leading-[120%]", {
                "text-24 md:text-48": full_screen,
                "text-20 md:text-28": !full_screen,
              })}
              dangerouslySetInnerHTML={{ __html: props.headline }}
            />
            <CmsLink cta={props.cta}>
              <CmsButton cta={props.cta} secondary color_palette={props.color_palette} />
            </CmsLink>
          </div>
        </div>
      </Container>
    </SpacingContainer>
  )
}

export default ImageTextTwoColumn
